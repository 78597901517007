import {useEffect, useState} from 'react';
import StepperContainer from './index.style';

type StepperProps = {
  currentStep: number;
  orientation: 'horizontal' | 'vertical';
};

function Stepper({currentStep, orientation = 'horizontal'}: StepperProps) {
  const [updatedStep, setUpdatedStep] = useState(0);
  const steps = [
    {number: 1, name: 'Create Account'},
    {number: 2, name: 'Connect your sales'},
    {number: 3, name: 'Complete your profile'},
    {number: 5, name: 'Get your Limit!'},
  ];

  useEffect(() => {
    if (currentStep === 4) {
      setUpdatedStep(3);
    } else {
      setUpdatedStep(currentStep);
    }
  }, [currentStep]);

  return (
    <StepperContainer>
      <div className={`stepper stepper--${orientation}`}>
        {steps.map((step, index) => (
          <div
            key={index}
            className={`stepper__step ${
              index === steps.length - 1 ? 'stepper__step--last' : ''
            }`}>
            <div className='stepper_content'>
              <div
                className={`stepper__node ${
                  updatedStep === step.number
                    ? 'stepper__node--active'
                    : updatedStep > step.number
                    ? 'stepper__node--completed'
                    : ''
                } `}
              />
              {index < steps.length - 1 && (
                <div
                  className={`stepper__line ${
                    updatedStep === step.number ? 'stepper__line--active' : ''
                  }`}
                />
              )}
            </div>
            <div
              className={`stepper__name ${
                updatedStep === step.number ? 'stepper__name--active' : ''
              }`}>
              {step.name}
            </div>
          </div>
        ))}
      </div>
    </StepperContainer>
  );
}

export default Stepper;
