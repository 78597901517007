import {INITIAL_LOAN_DATA} from '@common/constants';
import {createSlice} from '@reduxjs/toolkit';

interface ILoanApplicationInitial {
  data: {
    id: string;
    bankName: string;
    van: string;
    loanStatus: string;
    dsr: string;
    currency: string;
    loanType: string;
    remainingRepaymentAmountTillDate: number;
    repaidPercentageTillDate: number;
    shortfallPercentageTillDate: number;
    overduePercentageTillDate: number;
    repaidAmountTillDate: number;
    loanAmount: number;
    tenureInWeeks: string;
    grossLoanAmount: number;
    repaymentThrough: string;
    expected_first_repayment_date: string;
    actual_first_repayment_date: string;
    expected_last_repayment_date: string;
    estimated_last_repayment_date: string;
    actual_last_repayment_date: string;
    shortfallAmountTillDate: number;
    overdueAmountTillDate: number;
    expectedWeeklyRepaymentAmount: number;
    vanRoutingCodeType: string;
    vanRoutingCodeValue: string;
    expectedRepaymentAmountTillDate: number;
    fourWeeklyMinimumRepaymentAmount: number;
  };
}

const initialState: ILoanApplicationInitial = {
  data: INITIAL_LOAN_DATA,
};

const loanDetails = createSlice({
  name: 'loanDetailsStore',
  initialState,
  reducers: {
    setLoanDetailsData(state, actions) {
      state.data = actions.payload;
    },
    emptyLoanDetails(state, actions) {
      state.data = actions.payload;
    },
  },
});

const loanDetailsReducer = loanDetails.reducer;

export const loanDetailsAction = loanDetails.actions;

export default loanDetailsReducer;
